<!-- 修改密码 -->
<template>
  <div class="change-pass-page">
    <div class="form-wrapper">
      <van-form @failed="onFailed" ref="changePassForm">
        <van-field v-model="phone" required disabled placeholder="请输入账号或手机号">
        </van-field>
        <van-field
          v-model="password"
          :type="rightIcon2 ? '' : 'password'"
          required
          placeholder="请输入密码"
          :rules="[{ required: true, message: '' }]"
          name="password"
          :right-icon="rightIcon2 ? 'eye-o' : 'closed-eye'"
          @click-right-icon="
            rightIcon2 ? (rightIcon2 = false) : (rightIcon2 = true)
          "
          :readonly="readonlyInput1"
          @focus="readonlyInput1 = false"
          @blur="readonlyInput1 = true"
          maxlength="16"
          minlength="8"
        />
        <p class="remark">
          密码需包含大写字母、小写字母数字 和特殊符号，长度8-16位
        </p>
        <div class="btn-box">
          <div class="btn">
            <van-button round block type="primary" @click="onSubmit"
              >提交</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      userInfo: null,
      rightIcon0: false,
      rightIcon1: false,
      rightIcon2: false,
      phone: "",
      code: "",
      password: "",
      readonlyInput: true,
      readonlyInput1: true,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
  },

  methods: {
    onSubmit(values) {
      this.$message.success("申请成功")
    },
  },
};
</script>
<style lang="scss" scoped>
.change-pass-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  padding-bottom: 0.2rem;
  .form-wrapper {
    padding: 0.2rem;
    .remark {
      width: 100%;
      font-size: 0.16rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.2rem;
      background: #fff;
      padding: 0.2rem;
    }
    .btn-box {
      width: 90%;
      bottom: 0rem;
      margin:1rem 0.16rem 0;
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
